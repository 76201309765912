* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
/*Overlay*/
body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
body * {
  z-index: 1;
}
body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background: #1e1e24;
}
body::-webkit-scrollbar-thumb {
  background: #464747;
}
.sect {
  display: block;
  top: 100vh;
  background-color: burlywood;
  min-height: 100vh;
  text-align: center;
}
.text {
  text-align: center;
  padding: 50px;
}
.App {
  width: 100%;
  height: 100%;
}
/* .AppContainer {
  scroll-snap-type: y mandatory;
}*/
section {
  scroll-snap-align: start;
}
.AppContainer {
  height: 100%;
}
