@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Slabo+27px&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lemonada:wght@500&display=swap);
body {
  font-family: "Raleway", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}
/*Overlay*/
body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
body * {
  z-index: 1;
}
body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background: #1e1e24;
}
body::-webkit-scrollbar-thumb {
  background: #464747;
}
.sect {
  display: block;
  top: 100vh;
  background-color: burlywood;
  min-height: 100vh;
  text-align: center;
}
.text {
  text-align: center;
  padding: 50px;
}
.App {
  width: 100%;
  height: 100%;
}
/* .AppContainer {
  scroll-snap-type: y mandatory;
}*/
section {
  scroll-snap-align: start;
}
.AppContainer {
  height: 100%;
}

.fullscreen-video-wrap {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  min-height: calc(100 * var(--vh));
  overflow: hidden;
  z-index: -10;
}

@font-face {
  font-family: valorant;
  src: url("/static/media/Valorant Font.e7322a66.ttf");
}
@font-face {
  font-family: red-dead;
  src: url(/static/media/CHINESER.2105de11.TTF);
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(100 * var(--vh));
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
}
.caption {
  font-family: valorant;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  overflow: hidden;
}
h1 {
  margin: 5px;
  color: white;
  font-weight: 1;
  font-size: 60px;
  overflow: hidden;
}
p {
  color: white;
  text-align: center;
  font-family: "Raleway", sans-serif;
  width: 50%;
  padding: 20px;
  opacity: 0.4;
}
.dynamic {
  font-family: red-dead;
  letter-spacing: 10px;
  color: rgb(183, 194, 218);
  opacity: 0;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 3000ms;
          animation-duration: 3000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes example {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  80% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
}
@keyframes example {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  80% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
}

.line {
  border: 1px solid;
  height: 30px;
  background-color: whitesmoke;
  opacity: 0.7;
  align-items: center;
}
.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  font-size: 10px;
  text-transform: uppercase;
  color: whitesmoke;
  opacity: 0.4;
  z-index: 0;
}
.welcome h3 {
  overflow: hidden;
}
@media screen and (min-width: 769px) and (max-width: 1170px) {
  h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 769px) {
  h1 {
    font-size: 30px;
  }
  .line {
    max-height: 20px;
  }
  .welcome h3 {
    font-size: 10px;
    text-transform: uppercase;
  }
  p {
    font-size: 15px;
  }
  .dynamic {
    letter-spacing: 10px;
  }
}

:root {
  --bg-color: #031321;
  --primary-color: #a9add3;
  --secondary-color: #816ce0;
}

.navbar {
  position: fixed;
  top: 0rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  opacity: 1;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
  width: 100%;
  height: 10vh;
  height: calc(10 * var(--vh));
  min-height: 80px;
  overflow: hidden;
  transition: top 0.3s;
  z-index: 2;
}

.link,
.navbar a {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 0.1em;
  display: inline-block;
  padding: 15px 10px;
  position: relative;
  margin: 10px;
  cursor: pointer;
  opacity: 0.9;
}
.navbar .active {
  /* color: rgb(48, 104, 73); */
  color: rgb(255, 255, 0);
}
.menu .active {
  color: rgb(255, 255, 0);
}
.nav-link .link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-link .link:hover:after {
  width: 100%;
  left: 0;
  margin-left: auto;
}
.navbar .icon {
  display: none;
  position: fixed;
  top: 1rem;
  right: 2rem;
}

.fa-lg {
  overflow: hidden;
}
ul {
  list-style: none;
}
.navbar .nav-items {
  display: flex;
  margin-left: auto;
}

.menu {
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  min-height: calc(100 * var(--vh));
  z-index: 2;
  opacity: 0.9;
  background-color: rgb(72, 71, 75);
  overflow: hidden;
  -webkit-animation-name: colorChange;
          animation-name: colorChange;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
@-webkit-keyframes colorChange {
  from {
    opacity: 0.1;
    background-color: black;
  }
  to {
  }
}
@keyframes colorChange {
  from {
    opacity: 0.1;
    background-color: black;
  }
  to {
  }
}
.icon {
  transition: top 0.3s;
}
.menu .first {
  -webkit-animation-name: from-left;
          animation-name: from-left;
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.menu .second {
  -webkit-animation-name: from-left;
          animation-name: from-left;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.menu .third {
  -webkit-animation-name: from-right;
          animation-name: from-right;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.menu .fourth {
  -webkit-animation-name: from-right;
          animation-name: from-right;
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
@-webkit-keyframes from-left {
  from {
    transform: translateX(-100px);
  }
  to {
  }
}
@keyframes from-left {
  from {
    transform: translateX(-100px);
  }
  to {
  }
}
@-webkit-keyframes from-right {
  from {
    transform: translateX(100px);
  }
  to {
  }
}
@keyframes from-right {
  from {
    transform: translateX(100px);
  }
  to {
  }
}

@media screen and (max-width: 1170px) {
  .navbar .nav-items {
    display: none;
  }
  .menu li:not(:last-child) {
    display: block;
  }
  .menu .nav-items {
    display: block;
    text-align: center;
  }
  .navbar .icon {
    display: block;
    position: fixed;
    top: 0rem;
    right: 0rem;
    z-index: 10;
    transform: translateY(-4px);
  }
  .menu .icon {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10;
  }
  .menu a {
    color: #fff;
    text-decoration: none;
  }
}

.logo {
  margin-right: auto;
  position: fixed;
  top: 0rem;
  left: 1rem;
  opacity: 0.5;
  transition: top 0.3s;
}
@media screen and (min-width: 769px) and (max-width: 1170px) {
  .myLogo {
    width: 70px;
  }
}
@media screen and (max-width: 769px) {
  .myLogo {
    width: 60px;
  }
}

#projects {
  position: relative;
  background-color: rgb(180, 168, 168);
  height: 100vh;
  height: calc(100 * var(--vh));
  overflow-y: scroll;
  margin: 0;
  margin-top: 1px;
  padding: 0;
  z-index: 0;
}

#projects::-webkit-scrollbar {
  width: 2px;
}
#projects::-webkit-scrollbar-track {
  background: #1e1e24;
}
#projects::-webkit-scrollbar-thumb {
  background: #464747;
}
#projects h1 {
  font-family: valorant;
  margin-top: 7vh;
  margin-top: calc(7 * var(--vh));
}
.projectContainer {
  padding: 50px;
}
.projectContainer p {
  font-family: "Raleway", sans-serif;
}
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    90deg,
    rgb(44, 47, 48) 0%,
    rgb(68, 45, 68) 35%,
    rgb(4, 22, 34) 100%
  );
  z-index: -1;
  transition: background ease 3000ms;
}

.blur {
  background: rgb(0, 0, 0);
  opacity: 0.8;

  z-index: 1;
}
.box div {
  position: absolute;
  height: 60px;
  width: 60px;
  background-color: transparent;
  border: 6px solid rgba(255, 255, 255, 0.8);
}
.box div:nth-child(1) {
  top: 12%;
  left: 42%;
  -webkit-animation: animate 10s linear infinite;
          animation: animate 10s linear infinite;
}
.box div:nth-child(2) {
  top: 70%;
  left: 50%;
  -webkit-animation: animate 7s linear infinite;
          animation: animate 7s linear infinite;
}
.box div:nth-child(3) {
  top: 17%;
  left: 6%;
  -webkit-animation: animate 9s linear infinite;
          animation: animate 9s linear infinite;
}
.box div:nth-child(4) {
  top: 20%;
  left: 60%;
  -webkit-animation: animate 10s linear infinite;
          animation: animate 10s linear infinite;
}
.box div:nth-child(5) {
  top: 67%;
  left: 10%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
}
.box div:nth-child(6) {
  top: 80%;
  left: 70%;
  -webkit-animation: animate 12s linear infinite;
          animation: animate 12s linear infinite;
}
.box div:nth-child(7) {
  top: 60%;
  left: 80%;
  -webkit-animation: animate 15s linear infinite;
          animation: animate 15s linear infinite;
}
.box div:nth-child(8) {
  top: 32%;
  left: 25%;
  -webkit-animation: animate 16s linear infinite;
          animation: animate 16s linear infinite;
}
.box div:nth-child(9) {
  top: 90%;
  left: 25%;
  -webkit-animation: animate 9s linear infinite;
          animation: animate 9s linear infinite;
}
.box div:nth-child(10) {
  top: 20%;
  left: 80%;
  -webkit-animation: animate 5s linear infinite;
          animation: animate 5s linear infinite;
}
@-webkit-keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}
.projectitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: fixed;
  bottom: 0;
  height: 90vh;
  background-color: black;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  width: 50vw;
  border-radius: 10px 10px 0px 0px;
  -webkit-animation-name: evolve;
          animation-name: evolve;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: 600ms;
          animation-duration: 600ms;
}
.projectitem h3 {
  margin-bottom: auto;
}
.projectitem img {
  min-width: 100%;
  max-height: 40%;

  border-radius: 10px 10px 0px 0px;
  margin-bottom: auto;
}
@-webkit-keyframes evolve {
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
  }
}
@keyframes evolve {
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
  }
}
.hide {
  display: none;
}
#projects h3 {
  color: white;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
}
.projectContainer p {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 40px;
  background-color: #c4c4c46b;
}

.projectsListItem {
  position: relative;
  min-height: 100px;
  min-width: 50%;
  max-width: 80%;
  margin: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  /* background: linear-gradient(
    90deg,
    rgb(63, 51, 60) 0%,
    rgb(58, 123, 143) 35%,
    rgb(211, 218, 218) 100%
  ); */
  background-color: rgba(0, 0, 0, 0.116);

  border-radius: 10px;
  box-shadow: 5px 5px 6px 5px #000000ab;
  transition: scale ease 500ms, opacity ease 1000ms;
}

.open {
  opacity: 0;
}
.linktoproject {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: transform ease 300ms;
}
.linktoproject:hover {
  transform: scale(1.3);
}
.linktoproject h4 {
  color: rgb(13, 12, 58);
  /* font-family: 'Lemonada', cursive; */

  font-size: 1.2rem;
}
.technologies {
  display: flex;
  list-style: none;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.technologyitem {
  text-transform: capitalize;
  padding: 10px;
}

.devicon-github-plain,
.fa-link {
  font-size: 2rem;
}
.devicon-nodejs-plain-wordmark,
.devicon-express-original-wordmark {
  font-size: 4rem;
}
.devicon-react-original,
.devicon-react-original-wordmark,
.devicon-mongodb-plain-wordmark {
  font-size: 2rem;
  padding: 20px;
}
.devicon-vuejs-plain-wordmark {
  font-size: 3rem;
}
.devicon-angularjs-plain-wordmark {
  font-size: 4rem;
}
.hosting {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hosting a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}
.projectbox {
  display: grid;
  grid-gap: 0.7rem;
  grid-template-columns: repeat(3, 1fr);
}
.projectbox img {
  height: 20vh;
  width: 100%;
  border: 3px #fff solid;
}
.projectbox img:hover {
  opacity: 0.5;
  border-color: #eece1a;
  transition: all 0.5s ease-out;
}
.btn,
.btn-dark,
.btn-light {
  display: block;
  padding: 0.5rem 1rem;

  border: 0;
  margin-bottom: 0.3rem;
}
.btn:hover,
.btn-dark:hover,
.btn-light:hover {
  background: #eece1a;
  color: #000;
}

.btn-dark {
  background: black;
  color: #fff;
}

.btn-light {
  background: #c4c4c4;
  color: #333;
}

.item a {
  margin-top: 5px;
  text-decoration: none;
  padding: 10px;
}
.item {
  text-transform: capitalize;
}

.item .pimage {
  padding: 0;
}
.color-secondary {
  color: #eece1a;
}
@media screen and (min-width: 769px) and (max-width: 1170px) {
  .projectsList {
    grid-template-columns: auto;
  }
  .devicon-nodejs-plain-wordmark,
  .devicon-express-original-wordmark {
    font-size: 4rem;
  }
  .devicon-react-original,
  .devicon-react-original-wordmark,
  .devicon-mongodb-plain-wordmark {
    font-size: 2rem;
    padding: 15px;
  }
}
@media screen and (max-width: 769px) {
  .projectsList {
    grid-template-columns: auto;
  }
  .projectitem {
    width: 90vw;
    height: 80vh;
  }

  .projectsListItem {
    min-width: 80%;
  }
  .devicon-nodejs-plain-wordmark,
  .devicon-express-original-wordmark {
    font-size: 3rem;
  }
  .devicon-react-original,
  .devicon-react-original-wordmark,
  .devicon-mongodb-plain-wordmark {
    font-size: 1.5rem;
    padding: 4px;
  }
}
@media screen and (min-width: 1171px) {
  .projectbox {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 769px) and (max-width: 1170px) {
  .projectbox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .projectbox {
    grid-template-columns: repeat(2, 1fr);
  }
  .devicon-vuejs-plain-wordmark {
    font-size: 2rem;
  }
  .devicon-angularjs-plain-wordmark {
    font-size: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .projectbox {
    grid-template-columns: 1fr;
  }
  .projectContainer p {
    font-size: 12px;
  }
  .wrapper {
    height: 500%;
  }
}

#about {
  position: relative;
  background-color: rgb(180, 168, 168);
  height: 100vh;
  height: calc(100 * var(--vh));
  overflow-y: scroll;
  margin: 0;
  margin-top: 1px;
  padding: 0;
  z-index: 0;
}

#about::-webkit-scrollbar {
  width: 2px;
}
#about::-webkit-scrollbar-track {
  background: #1e1e24;
}
#about::-webkit-scrollbar-thumb {
  background: #464747;
}
#about h1 {
  font-family: valorant;
  margin-top: 7vh;
  margin-top: calc(7 * var(--vh));
}
#about p {
  font-family: "Roboto", sans-serif;
}
.aboutcontainer {
  width: 100%;
  height: 100%;
  padding: 50px;
}
.greeting {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: 1.2rem;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 40px;
  background-color: #c4c4c46b;
}
.wrapper2 {
  position: absolute;
  width: 100%;
  height: 500%;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgb(123, 121, 124) 0%,
    rgb(46, 134, 68) 35%,
    rgb(38, 3, 78) 100%
  );
  z-index: -1;
}
/* .first-part {
  display: flex;
  justify-content: center;
  align-items: center;
}
.first-part img {
  margin-left: 50px;
  border-radius: 50%;
}
.bio {
  padding-left: 10%;
}*/
.bio p {
  text-align: left;
  margin-left: 0;
  /* margin-top: 20px; */
  padding-left: 0;
  font-size: 20px;
  width: 100%;
}
.about-info {
  display: grid;
  grid-gap: 30px;
  grid-template-areas: "bioimage bio bio" "job1 job2 job3";
  grid-template-columns: repeat(3, 1fr);
}
.about-info .bio-image {
  grid-area: bioimage;
  margin: auto;
  border-radius: 50%;
  border: #eece1a 3px solid;
}
.about-info .bio {
  grid-area: bio;
  font-size: 1.5rem;
}
.about-info .job-1 {
  grid-area: job1;
  background: #515151 url(/static/media/project2.473d9f55.jpg) no-repeat;
}
.about-info .job-2 {
  grid-area: job2;
  background: #515151 url(/static/media/violin.af7bf668.jpg) no-repeat center center / cover;
}
.about-info .job-3 {
  grid-area: job3;
  background: #515151 url(/static/media/project5.babc9580.jpg) no-repeat;
}
.about-info .job {
  padding: 0.5rem;
  color: #fff;
  border-bottom: #eece1a 5px solid;
  margin: 10px;
  min-height: 200px;
}

.job p {
  text-align: left;
  padding: 20px;
  padding-left: 10px;
  width: 100%;
}
.job h3,
h6 {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .bio h3 {
    text-align: center;
  }
  .about-info {
    grid-template-areas: "bioimage" "bio" "job1" "job2" "job3";
    grid-template-columns: 1fr;
  }
  .greeting {
    font-size: 18px;
  }
  .about-info img {
    max-width: 200px;
  }
  .bio h3 {
    font-size: 20px;
    text-align: center;
  }
  .bio p {
    font-size: 1.2rem;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .greeting {
    font-size: 12px;
  }
  .bio h3 {
    font-size: 20px;
    text-align: center;
  }
  .bio p {
    font-size: 1.2rem;
    text-align: center;
  }
  .about-info img {
    max-width: 200px;
  }
}

#contact {
  position: relative;
  background-color: rgb(180, 168, 168);
  min-height: 100vh;
  min-height: calc(100 * var(--vh));
  overflow-y: scroll;
  margin: 0;
  margin-top: 1px;
  padding: 0;
  z-index: 0;
}

#contact::-webkit-scrollbar {
  width: 2px;
}
#contact::-webkit-scrollbar-track {
  background: #1e1e24;
}
#contact::-webkit-scrollbar-thumb {
  background: #464747;
}
#contact h1 {
  font-family: valorant;
  margin-top: 7vh;
  margin-top: calc(7 * var(--vh));
}
#contact p {
  font-family: "Roboto", sans-serif;
}
.wrapper3 {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgb(2, 2, 43) 0%,
    rgb(41, 48, 44) 35%,
    rgb(0, 48, 77) 100%
  );
  z-index: -1;
}
.contactcontainer {
  padding: 50px;
  height: 100%;
  width: 100%;
}
.boxes {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
}
.boxes div {
  font-size: 2rem;
  border: 3px #fff solid;
  padding: 1.5rem 2.5rem;
  margin-bottom: 3rem;
  transition: all 0.5s ease-out;
}
.boxes div:hover {
  padding: 0.5rem 1.5rem;
  background: #eece1a;
  color: #000;
}
.boxes div:hover span {
  color: #000;
}
@media screen and (max-width: 500px) {
  .boxes div {
    font-size: 1rem;
  }
}

