.logo {
  margin-right: auto;
  position: fixed;
  top: 0rem;
  left: 1rem;
  opacity: 0.5;
  transition: top 0.3s;
}
@media screen and (min-width: 769px) and (max-width: 1170px) {
  .myLogo {
    width: 70px;
  }
}
@media screen and (max-width: 769px) {
  .myLogo {
    width: 60px;
  }
}
