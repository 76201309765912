:root {
  --bg-color: #031321;
  --primary-color: #a9add3;
  --secondary-color: #816ce0;
}

.navbar {
  position: fixed;
  top: 0rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  opacity: 1;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
  width: 100%;
  height: 10vh;
  height: calc(10 * var(--vh));
  min-height: 80px;
  overflow: hidden;
  transition: top 0.3s;
  z-index: 2;
}

.link,
.navbar a {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 0.1em;
  display: inline-block;
  padding: 15px 10px;
  position: relative;
  margin: 10px;
  cursor: pointer;
  opacity: 0.9;
}
.navbar .active {
  /* color: rgb(48, 104, 73); */
  color: rgb(255, 255, 0);
}
.menu .active {
  color: rgb(255, 255, 0);
}
.nav-link .link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-link .link:hover:after {
  width: 100%;
  left: 0;
  margin-left: auto;
}
.navbar .icon {
  display: none;
  position: fixed;
  top: 1rem;
  right: 2rem;
}

.fa-lg {
  overflow: hidden;
}
ul {
  list-style: none;
}
.navbar .nav-items {
  display: flex;
  margin-left: auto;
}

.menu {
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  min-height: calc(100 * var(--vh));
  z-index: 2;
  opacity: 0.9;
  background-color: rgb(72, 71, 75);
  overflow: hidden;
  animation-name: colorChange;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
@keyframes colorChange {
  from {
    opacity: 0.1;
    background-color: black;
  }
  to {
  }
}
.icon {
  transition: top 0.3s;
}
.menu .first {
  animation-name: from-left;
  animation-duration: 800ms;
  animation-iteration-count: 1;
}
.menu .second {
  animation-name: from-left;
  animation-duration: 500ms;
  animation-iteration-count: 1;
}
.menu .third {
  animation-name: from-right;
  animation-duration: 500ms;
  animation-iteration-count: 1;
}
.menu .fourth {
  animation-name: from-right;
  animation-duration: 800ms;
  animation-iteration-count: 1;
}
@keyframes from-left {
  from {
    transform: translateX(-100px);
  }
  to {
  }
}
@keyframes from-right {
  from {
    transform: translateX(100px);
  }
  to {
  }
}

@media screen and (max-width: 1170px) {
  .navbar .nav-items {
    display: none;
  }
  .menu li:not(:last-child) {
    display: block;
  }
  .menu .nav-items {
    display: block;
    text-align: center;
  }
  .navbar .icon {
    display: block;
    position: fixed;
    top: 0rem;
    right: 0rem;
    z-index: 10;
    transform: translateY(-4px);
  }
  .menu .icon {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10;
  }
  .menu a {
    color: #fff;
    text-decoration: none;
  }
}
